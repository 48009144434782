<template>
    <div>
        <h1>Attendance</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import Options from "./attendance_opt";
    import Results from "./utils/Results";

    export default {
        name: 'attendance',
        components: {
            Results,
            Options
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    dateFrom: {val: this.startDate(), qsField: '[date_from]'},
                    dateTo: {val: new Date().toLocaleDateString(), qsField: '[date_to]'},
                    cdiLocationID: {val: '0', qsField: '[numbers][uss.cdi_location_id]'},
                    shift: {val: '0', qsField: '[numbers][us.user_staff_shift_id]'},
                    type: {val: '0', qsField: '[type]'},
                    showPoints: {val: '0', qsField: '[points]'}
                },
                groupByFields: {},
                dataFields: {}
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            },
            startDate() {
                let now = new Date();
                return new Date(now.setDate(now.getDate() - now.getDay())).toLocaleDateString()
            }
        },
        created() {
            this.$appStore.setTitle(['Attendance']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupbyFields, this.dataFields);
        }
    }
</script>