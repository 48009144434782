<template>
    <div class='panel panel-default '>
        <div class='panel-heading'></div>
        <div class='row panel-body'>
            <div class='date_picker col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Date From</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.dateFrom.val" />
            </div>
            <div class='date_picker col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Date To</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.dateTo.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationID.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{cdiLocation}}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Shift</label>
                <select class="form-control" v-model="state.optionFields.shift.val">
                    <option value="0">All</option>
                    <option v-for="[shiftID, shift] in cache.shiftCache.SHIFTS" v-bind:key="shiftID" :value="shiftID">{{shift}}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Type</label>
                <select class="form-control" v-model="state.optionFields.type.val">
                    <option value="0">Absences</option>
                    <option value="1">Missing Clock In/Out</option>
                    <option value="2">Long Shifts</option>
                    <option value="3">Excessive OT</option>
                    <option value="4">No Lunch Break</option>
                    <option value="5">Extra Shifts</option>
                </select>
            </div>
            <div>
                <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                    <label><input type='checkbox' :true-value="1" :false-value="0" v-model="state.optionFields.showPoints.val" > Show Points</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import shiftCache from '@/cache/shift.cache.js';
    import DatePicker from "@/components/utils/DatePicker";

    export default {
        name: 'Options',
        components: { DatePicker },
        data() {
            return {
                state: store.state,
                cache: {
                    cdiLocationCache,
                    shiftCache,
                }
            }
        }
    }
</script>